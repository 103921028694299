













































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































/* global $ */
import {
  WarrantyInfoResponse,
  WarrantyDropdown,
  WarrantyResultBuilding,
  WarrantyResultAuto,
  WarrantyDetail,
  createWarrantyResultBuilding,
  updateWarrantyResultBuilding,
  createWarrantyResultAuto,
  updateWarrantyResultAuto
} from '@/types/api/Warranty';
import {
  createCustomerDetailResponse,
  CustomerResponse,
  updateCustomerDetails
} from '@/types/api/CustomerList';
import Vue from 'vue';
import mixinsVue from './mixins.vue';
import {
  initRegisterWarrantyDetails,
  resetRegisterWarrantyDetails
} from '@/types/api/RegisterWarrantyWithCustomer';
import {
  ProductRemainderSqFt,
  ProductRemainerSqftListResponse
} from '@/types/api/Product';
import {
  NonAssignedWarrantySeal,
  NonAssignedWarrantySealListResponse
} from '@/types/api/WarrantySeal';
import { AxiosResponse } from 'axios';
import AutoDecimalInput from '@/components/helpers/AutoDecimalInput.vue';
import {
  DropdownOption,
  countries,
  getCountryStates
} from '@/dropdown-options';
export default Vue.extend({
  'name': 'ModalViewCustomer',
  'mixins': [mixinsVue],
  'components': {
    AutoDecimalInput
  },
  'props': {
    'value': {
      'required': true
    },
    'title': {
      'type': String,
      'default': "Customer's Details"
    }
  },
  'computed': {
    isSuperAdminOrAdmin (): boolean {
      return this.$store.getters.isSuperAdmin || this.$store.getters.isAdmin;
    },
    viewingCustomerWarrantyType (): number {
      const currentWarranty =
        this.warrantyDropdown[this.currentViewingWarranty];

      if (currentWarranty) {
        return currentWarranty.warrantyType;
      }
      return 0;
    },
    currentWarrantyDetails ():
      | WarrantyResultBuilding
      | WarrantyResultAuto
      | null {
      const currentWarranty =
        this.warrantyDropdown[this.currentViewingWarranty];

      if (currentWarranty) {
        return currentWarranty.warrantyDetails;
      }
      return null;
    },
    hasAnyUpdating (): boolean {
      return this.updateStatus.warrantyDetailId !== -1;
    },
    hasAnyAdminUpdating (): boolean {
      return this.adminUpdateStatus.warrantyDetailId !== -1;
    },
    stateDropdowns ():DropdownOption[] {
      const selectedCountry = this.customerDetailEdit.country;

      const states = getCountryStates(selectedCountry);

      states.unshift({
        'text': states.length > 0 ? 'Please select state' : 'Please select country first',
        'value': ''
      });

      return states;
    },
    stateDropdownsBuilding ():DropdownOption[] {
      const selectedCountry = this.buildingDetailEdit.country;

      const states = getCountryStates(selectedCountry);

      states.unshift({
        'text': states.length > 0 ? 'Please select state' : 'Please select country first',
        'value': ''
      });

      return states;
    }
  },
  'watch': {
    value (customerId) {
      if (customerId) {
        this.axios.get(`id/${customerId}`).then(({ data }) => {
          const response = data as CustomerResponse;

          updateCustomerDetails(this.customerDetail, response.result);

          this.viewingTab = 1;
          this.grabData();
        });
      }
    }
  },
  data () {
    const customerDetail = createCustomerDetailResponse();
    const customerDetailEdit = createCustomerDetailResponse();
    const updateForm = initRegisterWarrantyDetails();

    const buildingDetailEdit = createWarrantyResultBuilding();
    const vehicleDetailEdit = createWarrantyResultAuto();
    return {
      customerDetail,
      'warrantyTypes': [
        {
          'text': 'Warranty Type: Auto',
          'value': 2
        },
        {
          'text': 'Warranty Type: Building',
          'value': 1
        }
      ] as Array<DropdownOption>,
      'viewingTab': 1,
      'warrantyDropdown': [] as WarrantyDropdown[],
      'currentViewingWarranty': -1 as number,
      'updateStatus': {
        'value': 'Registered',
        'warrantyDetailId': -1
      },
      'adminUpdateStatus': {
        'value': 'Requested',
        'warrantyDetailId': -1
      },
      updateForm,
      'warrantySeals': [] as Array<NonAssignedWarrantySeal>,
      'warrantySealDropdownList': [] as Array<DropdownOption>,
      'productModels': [] as Array<ProductRemainderSqFt>,
      'productDropdownList': [] as Array<DropdownOption>,
      'updateMessage': '',
      'previousViewingIndex': 0,
      countries,
      'malaysiaStates': [] as Array<DropdownOption>,
      'autoTintedLocations': [] as Array<DropdownOption>,

      'isEditingCustomer': false,
      customerDetailEdit,
      'updateCustomerMessage': '',

      'isEditingBuilding': false,
      'updateBuildingMessage': '',
      vehicleDetailEdit,

      'isEditingVehicle': false,
      'updateVehicleMessage': '',
      buildingDetailEdit
    };
  },
  mounted () {
    //
  },
  'methods': {
    selectViewTab (viewTabIndex: number) {
      this.viewingTab = viewTabIndex;
    },
    grabData () {
      this.warrantyDropdown = [];
      this.axios.get(`warranty/list/${this.value}`).then(({ data }) => {
        const response = data as WarrantyInfoResponse;

        if (
          response &&
          response.result &&
          response.result.autoWarranties &&
          response.result.autoWarranties.autoWarranties.length > 0
        ) {
          const warrantyDropdowns: WarrantyDropdown[] =
            response.result.autoWarranties.autoWarranties.map((w) => {
              return {
                'invoice': w.invoice,
                'warrantyType': 2,
                'warrantyTypeDisplay': w.warrantyType,
                'warrantyDetails': w
              } as WarrantyDropdown;
            });

          this.warrantyDropdown.push(...warrantyDropdowns);
        }

        if (
          response &&
          response.result &&
          response.result.buildingWarranties &&
          response.result.buildingWarranties.buildingWarranties.length > 0
        ) {
          const warrantyDropdowns: WarrantyDropdown[] =
            response.result.buildingWarranties.buildingWarranties.map((w) => {
              return {
                'invoice': w.invoice,
                'warrantyType': 1,
                'warrantyTypeDisplay': w.warrantyType,
                'warrantyDetails': w
              } as WarrantyDropdown;
            });

          this.warrantyDropdown.push(...warrantyDropdowns);
        }

        if (this.warrantyDropdown.length > 0) {
          if (this.warrantyDropdown.length > this.previousViewingIndex) {
            this.currentViewingWarranty = this.previousViewingIndex;
          } else {
            this.currentViewingWarranty = 0;
          }
        } else {
          this.currentViewingWarranty = -1;
        }
      });
    },
    getDateDisplay (dateInIso8601: string) {
      const dateInUtc = window.moment.utc(dateInIso8601).toDate();
      return window.moment(dateInUtc).local().format('DD/MM/YYYY');
    },
    onSubmit () {
      // do nothing
    },
    setUpdating (updatingWarrantyDetailId: number) {
      if (this.updateStatus.value === 'Registered') {
        this.clearUpdateStatus();
      } else {
        resetRegisterWarrantyDetails(this.updateForm);
        (this.$refs.form as any).reset();

        this.updateStatus.warrantyDetailId = updatingWarrantyDetailId;
        this.getNonAssignedWarrantySeals();
        this.getProductRemainderSqfts();

        this.$nextTick(() => {
          // @ts-expect-error: Ignore error explicitly.
          $(this.$refs.inputStartDate).daterangepicker({
            'singleDatePicker': true,
            'autoApply': true,
            'locale': {
              'format': 'DD/MM/YYYY'
            }
          });

          $(this.$refs.inputStartDate).on(
            'apply.daterangepicker',
            (ev, picker) => {
              this.updateForm.startDateUi =
                picker.startDate.format('DD/MM/YYYY');
            }
          );
        });

        const currentWarranty = this.currentWarrantyDetails
          ? this.currentWarrantyDetails.warrantyDetails.find(
            (d) => d.id === this.updateStatus.warrantyDetailId
          )
          : null;
        this.updateForm.startDateUi = currentWarranty
          ? currentWarranty.startDate
            ? this.getDateDisplay(currentWarranty.startDate)
            : ''
          : '';
        this.updateForm.totalSqFt = currentWarranty
          ? currentWarranty.totalSqFt
          : null;
        this.updateForm.tintedLocation = currentWarranty
          ? currentWarranty.tintedLocation
          : '';
      }
    },
    setAdminUpdating (updatingWarrantyDetailId: number) {
      if (this.adminUpdateStatus.value === 'Requested') {
        this.clearUpdateStatus();
      } else {
        this.adminUpdateStatus.warrantyDetailId = updatingWarrantyDetailId;
      }
    },
    updateCustomerWarranty () {
      (this.$refs.form as any).validate().then((result: boolean) => {
        if (result) {
          this.previousViewingIndex = this.currentViewingWarranty;
          this.updateMessage = '';

          if (this.isSuperAdminOrAdmin) {
            const currentWarranty = this.currentWarrantyDetails
              ? this.currentWarrantyDetails.warrantyDetails.find(
                (d) => d.id === this.adminUpdateStatus.warrantyDetailId
              )
              : null;

            let apiPath = null;

            if (currentWarranty?.modifyRequest != null) {
              apiPath = 'warranty/modify';
            } else if (currentWarranty?.claimRequest != null) {
              apiPath = 'warranty/claim';
            }

            if (apiPath != null) {
              let putRequest = null as unknown;
              if (this.adminUpdateStatus.value === 'Approved') {
                putRequest = this.axios.put(`${apiPath}/approve`, null, {
                  'params': {
                    'warrantyDetailId': this.adminUpdateStatus.warrantyDetailId
                  }
                });
              }

              if (this.adminUpdateStatus.value === 'Rejected') {
                putRequest = this.axios.put(`${apiPath}/reject`, null, {
                  'params': {
                    'warrantyDetailId': this.adminUpdateStatus.warrantyDetailId
                  }
                });
              }

              if (putRequest !== null) {
                (putRequest as Promise<AxiosResponse<any>>)
                  .then(({ data }) => {
                    this.updateMessage = data.message;

                    this.clearUpdateStatus();
                    this.grabData();
                  })
                  .catch((error) => {
                    if (
                      error.response &&
                      error.response.data &&
                      error.response.data.message
                    ) {
                      this.updateMessage = error.response.data.message;
                    } else {
                      this.updateMessage = 'An error occurs, please try again.';
                    }
                  });
              }
            }
          } else {
            if (this.updateStatus.value === 'Modify') {
              const momentStartDate = window.moment(
                this.updateForm.startDateUi,
                'DD/MM/YYYY'
              );

              const currentWarranty = this.currentWarrantyDetails
                ? this.currentWarrantyDetails.warrantyDetails.find(
                  (d) => d.id === this.updateStatus.warrantyDetailId
                )
                : null;
              const requestForm = {
                'warrantyDetailId': this.updateStatus.warrantyDetailId,
                'productId': this.updateForm.productId,
                'warrantySealId': this.updateForm.warrantySealId,
                'totalSqFt': parseFloat(this.updateForm.totalSqFt + '' ?? '0'),
                'startDate': momentStartDate.toISOString(),
                'validYears': currentWarranty ? currentWarranty.validYears : 0
              };

              this.axios
                .put('warranty/modify/request', requestForm)
                .then(({ data }) => {
                  this.updateMessage = data.message;

                  this.clearUpdateStatus();
                  this.grabData();
                })
                .catch((error) => {
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                  ) {
                    this.updateMessage = error.response.data.message;
                  } else {
                    this.updateMessage = 'An error occurs, please try again.';
                  }
                });
            }

            if (this.updateStatus.value === 'Claimed') {
              const requestForm = {
                'warrantyDetailId': this.updateStatus.warrantyDetailId,
                'productId': this.updateForm.productId,
                'warrantySealId': this.updateForm.warrantySealId
              };

              this.axios
                .put('warranty/claim/request', requestForm)
                .then(({ data }) => {
                  this.updateMessage = data.message;

                  this.clearUpdateStatus();
                  this.grabData();
                })
                .catch((error) => {
                  if (
                    error.response &&
                    error.response.data &&
                    error.response.data.message
                  ) {
                    this.updateMessage = error.response.data.message;
                  } else {
                    this.updateMessage = 'An error occurs, please try again.';
                  }
                });
            }
          }
        } else {
          this.updateMessage =
            'Validation error. Please check your inputs and retry again.';
        }
      });
    },
    canUpdate (detail: WarrantyDetail) {
      if (detail.status === 'Registered') {
        if (this.hasAnyUpdating) {
          return this.updateStatus.warrantyDetailId === detail.id;
        }

        return true;
      }

      return false;
    },
    adminCanUpdate (detail: WarrantyDetail) {
      if (detail.status === 'Requested') {
        if (this.hasAnyAdminUpdating) {
          return this.adminUpdateStatus.warrantyDetailId === detail.id;
        }

        return true;
      }

      return false;
    },
    clearUpdateStatus (): void {
      this.updateStatus.value = 'Registered';
      this.updateStatus.warrantyDetailId = -1;

      this.adminUpdateStatus.value = 'Requested';
      this.adminUpdateStatus.warrantyDetailId = -1;
    },
    dismissAndReset () {
      (this as any).hide().then(() => {
        this.discardEditCustomer();
        this.discardEditVehicle();
        this.discardEditBuilding();

        this.clearUpdateStatus();
        this.previousViewingIndex = 0;
        this.$emit('dismissed');
      });
    },
    getNonAssignedWarrantySeals () {
      this.axios.get('non-assigned/all').then(({ data }) => {
        const response = data as NonAssignedWarrantySealListResponse;
        this.warrantySeals = response.result.warrantySeals;
        this.searchWarrantySealList(null);
      });
    },
    searchWarrantySealList (searchTerm: string | null) {
      this.warrantySealDropdownList = this.warrantySeals.map((ws) => ({
        'text': `Run No. ${ws.runNo}`,
        'value': ws.id
      }));

      if (searchTerm && searchTerm.length > 0) {
        this.warrantySealDropdownList = this.warrantySealDropdownList.filter(
          (d) => d.text.indexOf(searchTerm) > -1
        );
      }
    },
    getSelectedWarrantySealDisplay (warrantySealId: number) {
      const warrantySeal = this.warrantySeals.find(
        (ws) => ws.id === warrantySealId
      );

      return warrantySeal ? `Run No. ${warrantySeal.runNo}` : 'Not Assigned';
    },
    totalSqftValidation () {
      if (this.updateForm.productId) {
        const product = this.productModels.find(
          (p) => p.id === this.updateForm.productId
        );

        if (product) {
          return `required|double:1|min_value:0.1|max_value:${product.unregSqFt.toFixed(
            1
          )}`;
        }
      }
      return 'required|double:1|min_value:0.1';
    },
    getProductRemainderSqfts () {
      this.axios.get('product/remainder-sq-ft').then(({ data }) => {
        const response = data as ProductRemainerSqftListResponse;
        this.productModels = response.result.products;
        this.searchProductList(null);
      });
    },
    searchProductList (searchTerm: string | null) {
      this.productDropdownList = this.productModels.map((p) => ({
        'text': `${p.model} - Serial No: ${
          p.serialNo
        }, Remaining Sqft: ${p.unregSqFt.toFixed(1)}`,
        'value': p.id
      }));

      if (searchTerm && searchTerm.length > 0) {
        this.productDropdownList = this.productDropdownList.filter(
          (d) => d.text.indexOf(searchTerm) > -1
        );
      }
    },
    getSelectedProduct (productId: number) {
      const product = this.productModels.find((p) => p.id === productId);

      if (product) {
        return product;
      }
      return null;
    },
    exportAll () {
      const customerName = this.customerDetail.name.replaceAll(' ', '_');
      this.axios({
        'url': `Warranty/export/${this.value}`,
        'method': 'GET',
        'responseType': 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `customer-${customerName}-export.xlsx`); // or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    editCustomer () {
      this.isEditingCustomer = true;
      updateCustomerDetails(this.customerDetailEdit, this.customerDetail);
    },
    discardEditCustomer () {
      this.isEditingCustomer = false;
      this.updateCustomerMessage = '';
    },
    refreshCustomerData () {
      this.axios.get(`id/${this.value}`).then(({ data }) => {
        const response = data as CustomerResponse;

        updateCustomerDetails(this.customerDetail, response.result);
      });
    },
    saveCustomer () {
      (this.$refs.formEditCustomer as any).validate().then((result: boolean) => {
        if (result) {
          this.updateCustomerMessage =
            'Saving your edit... Please wait.';

          const editCustomerForm = {
            'customerId': this.customerDetailEdit.id,
            'name': this.customerDetailEdit.name,
            'phone': this.customerDetailEdit.phone,
            'email': this.customerDetailEdit.email,
            'ic': this.customerDetailEdit.ic,
            'customerAddress': {
              'address1': this.customerDetailEdit.address1,
              'address2': this.customerDetailEdit.address2,
              'postcode': this.customerDetailEdit.postcode,
              'city': this.customerDetailEdit.city,
              'state': this.customerDetailEdit.state,
              'country': this.customerDetailEdit.country
            }
          };
          this.axios
            .put('customer', editCustomerForm)
            .then(() => {
              this.updateCustomerMessage =
            'Customer details updated successfully!';

              setTimeout(() => {
                this.updateCustomerMessage = '';
              }, 5000);

              this.isEditingCustomer = false;
              this.refreshCustomerData();
            })
            .catch((err) => {
              if (
                err.response &&
              err.response.status === 422 &&
              err.response.data
              ) {
                const result = err.response.data.result;
                if (result) {
                  (this.$refs.formEditCustomer as any).setErrors(result);
                }

                this.updateCustomerMessage =
            'Error updating customer details. Please try again.';
              }
            });
        } else {
          this.updateCustomerMessage =
            'Validation error. Please check your inputs and retry again.';
        }
      });
    },
    editVehicle () {
      this.isEditingVehicle = true;
      updateWarrantyResultAuto(this.vehicleDetailEdit, this.currentWarrantyDetails as WarrantyResultAuto);

      this.$nextTick(() => {
        // @ts-expect-error: Ignore error explicitly.
        $(this.$refs.inputInstallationDateVehicle).daterangepicker({
          'singleDatePicker': true,
          'autoApply': true,
          'locale': {
            'format': 'DD/MM/YYYY'
          }
        });

        $(this.$refs.inputInstallationDateVehicle).on(
          'apply.daterangepicker',
          (ev, picker) => {
            this.vehicleDetailEdit.installationDateUi = picker.startDate.format('DD/MM/YYYY');
          }
        );
      });
    },
    discardEditVehicle () {
      this.isEditingVehicle = false;
      this.updateVehicleMessage = '';
    },
    saveVehicle () {
      this.updateVehicleMessage = '';
      this.updateBuildingMessage = '';
      (this.$refs.formEditVehicle as any).validate().then((result: boolean) => {
        if (result) {
          this.updateVehicleMessage =
            'Saving your edit... Please wait.';
          const momentInstallationDate = window.moment(
            this.vehicleDetailEdit.installationDateUi,
            'DD/MM/YYYY'
          );

          this.vehicleDetailEdit.installationDate = momentInstallationDate.toISOString();

          const editVehicleForm = {
            'warrantyDetailId': this.vehicleDetailEdit.warrantyId,
            'carRegistrationNo': this.vehicleDetailEdit.carRegistrationNo,
            'chasisNo': this.vehicleDetailEdit.chasisNo,
            'make': this.vehicleDetailEdit.make,
            'model': this.vehicleDetailEdit.model,
            'colour': this.vehicleDetailEdit.colour,
            'invoice': this.vehicleDetailEdit.invoice,
            'installationDate': momentInstallationDate
          };

          this.axios
            .put('warranty/UpdateAutoDetails', editVehicleForm)
            .then(() => {
              this.isEditingVehicle = false;
              this.updateVehicleMessage =
            'Vehicle details updated successfully!';

              (this.currentWarrantyDetails as WarrantyResultAuto).carRegistrationNo = this.vehicleDetailEdit.carRegistrationNo;
              (this.currentWarrantyDetails as WarrantyResultAuto).chasisNo = this.vehicleDetailEdit.chasisNo;
              (this.currentWarrantyDetails as WarrantyResultAuto).make = this.vehicleDetailEdit.make;
              (this.currentWarrantyDetails as WarrantyResultAuto).model = this.vehicleDetailEdit.model;
              (this.currentWarrantyDetails as WarrantyResultAuto).colour = this.vehicleDetailEdit.colour;
              (this.currentWarrantyDetails as WarrantyResultAuto).invoice = this.vehicleDetailEdit.invoice;
              (this.currentWarrantyDetails as WarrantyResultAuto).installationDate = this.vehicleDetailEdit.installationDate;

              setTimeout(() => {
                this.updateVehicleMessage = '';
              }, 5000);
            })
            .catch((err) => {
              if (
                err.response &&
              err.response.status === 422 &&
              err.response.data
              ) {
                const result = err.response.data.result;
                if (result) {
                  (this.$refs.formEditVehicle as any).setErrors(result);
                }

                this.updateVehicleMessage =
            'Error updating vehicle details. Please try again.';
              }
            });
        } else {
          this.updateVehicleMessage =
            'Validation error. Please check your inputs and retry again.';
        }
      });
    },
    editBuilding () {
      this.isEditingBuilding = true;
      updateWarrantyResultBuilding(this.buildingDetailEdit, this.currentWarrantyDetails as WarrantyResultBuilding);

      this.$nextTick(() => {
        // @ts-expect-error: Ignore error explicitly.
        $(this.$refs.inputInstallationDateBuilding).daterangepicker({
          'singleDatePicker': true,
          'autoApply': true,
          'locale': {
            'format': 'DD/MM/YYYY'
          }
        });

        $(this.$refs.inputInstallationDateBuilding).on(
          'apply.daterangepicker',
          (ev, picker) => {
            this.buildingDetailEdit.installationDateUi = picker.startDate.format('DD/MM/YYYY');
          }
        );
      });
    },
    discardEditBuilding () {
      this.isEditingBuilding = false;
      this.updateBuildingMessage = '';
    },
    saveBuilding () {
      this.updateVehicleMessage = '';
      this.updateBuildingMessage = '';
      (this.$refs.formEditBuilding as any).validate().then((result: boolean) => {
        if (result) {
          this.updateBuildingMessage =
            'Saving your edit... Please wait.';
          const momentInstallationDate = window.moment(
            this.buildingDetailEdit.installationDateUi,
            'DD/MM/YYYY'
          );

          this.buildingDetailEdit.installationDate = momentInstallationDate.toISOString();

          const editBuildingForm = {
            'warrantyDetailId': this.buildingDetailEdit.warrantyId,
            'address1': this.buildingDetailEdit.adress1,
            'address2': this.buildingDetailEdit.adress2,
            'postcode': this.buildingDetailEdit.postcode,
            'city': this.buildingDetailEdit.city,
            'state': this.buildingDetailEdit.state,
            'country': this.buildingDetailEdit.country,
            'invoice': this.buildingDetailEdit.invoice,
            'installationDate': momentInstallationDate
          };

          this.axios
            .put('warranty/UpdateBuildingDetails', editBuildingForm)
            .then(() => {
              this.isEditingBuilding = false;
              this.updateBuildingMessage =
            'Building details updated successfully!';

              (this.currentWarrantyDetails as WarrantyResultBuilding).adress1 = this.buildingDetailEdit.adress1;
              (this.currentWarrantyDetails as WarrantyResultBuilding).adress2 = this.buildingDetailEdit.adress2;
              (this.currentWarrantyDetails as WarrantyResultBuilding).postcode = this.buildingDetailEdit.postcode;
              (this.currentWarrantyDetails as WarrantyResultBuilding).city = this.buildingDetailEdit.city;
              (this.currentWarrantyDetails as WarrantyResultBuilding).state = this.buildingDetailEdit.state;
              (this.currentWarrantyDetails as WarrantyResultBuilding).country = this.buildingDetailEdit.country;
              (this.currentWarrantyDetails as WarrantyResultBuilding).invoice = this.buildingDetailEdit.invoice;
              (this.currentWarrantyDetails as WarrantyResultBuilding).installationDate = this.buildingDetailEdit.installationDate;

              setTimeout(() => {
                this.updateBuildingMessage = '';
              }, 5000);
            })
            .catch((err) => {
              if (
                err.response &&
              err.response.status === 422 &&
              err.response.data
              ) {
                const result = err.response.data.result;
                if (result) {
                  (this.$refs.formEditBuilding as any).setErrors(result);
                }

                this.updateBuildingMessage =
            'Error updating building details. Please try again.';
              }
            });
        } else {
          this.updateBuildingMessage =
            'Validation error. Please check your inputs and retry again.';
        }
      });
    }
  }
});
